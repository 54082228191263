<template>
  <div>
    <div class="card-deck">
      <InfoCard
        :value="userTradingStats.totalGiftcardTraded"
        :isNaira="false"
        title="Giftcard Traded"
        icon="gift"
      />
      <InfoCard
        :value="userTradingStats.totalCompletedGiftcards"
        :isNaira="false"
        title="Completed Giftcards"
        icon="gift"
      />
      <InfoCard
        :value="userTradingStats.totalCancelledGiftcards"
        :isNaira="false"
        title="Cancelled Giftcards"
        icon="gift"
      />

      <InfoCard
        :value="userTradingStats.totalPendingGiftcards"
        :isNaira="false"
        title="Pending Giftcards"
        icon="gift"
      />
    </div>
    <br />
    <div class="card-deck">
      <InfoCard
        :value="userTradingStats.sumCompletedGiftcards"
        :isNaira="true"
        title="Sum Completed Giftcards"
        icon="card"
      />
      <InfoCard
        :value="userTradingStats.sumPendingGiftcards"
        :isNaira="true"
        title="Sum Pending Giftcards"
        icon="card"
      />
      <InfoCard
        :value="userTradingStats.sumCancelledGiftcards"
        :isNaira="true"
        title="Sum Cancelled Giftcards"
        icon="card"
      />
    </div>
    <br />
  </div>
</template>

<script>
import InfoCard from "@/components/dashboard/InfoCard.vue";
export default {
  name: "UserTradingStat",
  components: { InfoCard },
  props: ["userTradingStats"],
};
</script>
