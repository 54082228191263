<template>
  <div>
    <Spinner v-if="!userDetails" />
    <div v-else class="card-group justify-content-between">
      <div class="card col-md-8">
        <UserBasicInfo v-if="userDetails" :userDetails="userDetails" />
      </div>
      <div class="mx-2"></div>
      <div class="card col-md-4">
        <UserWalletInfo
          v-if="userWalletDetails"
          :userWalletDetails="userWalletDetails"
          :userDetails="userDetails"
        />
      </div>
    </div>
    <UserTradingStats
      class="my-4 mt-5 pt-3"
      v-if="userTradingStats"
      :userTradingStats="userTradingStats"
    />
    <UserTxn class="mb-5" :userId="userId" v-if="userId" />

    <UserKycInfo :userDetails="userDetails" v-if="userDetails" />

    <p class="mt-4 btnd btn-success small" @click="goBack">Go Back</p>
  </div>
</template>

<script>
import UserService from "@/services/user.service.js";
import UserTxn from "./components/UserTxn";
import UserBasicInfo from "./components/UserBasicInfo.vue";
import UserWalletInfo from "./components/UserWalletInfo";
import UserTradingStats from "./components/UserTradingStats.vue";
import UserKycInfo from "./components/UserKycInfo.vue";
import Spinner from "@/components/Spinner";
export default {
  name: "User",
  components: {
    UserTxn,
    UserBasicInfo,
    UserWalletInfo,
    Spinner,
    UserTradingStats,
    UserKycInfo,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes("users");
    });
  },
  data() {
    return {
      usersOpened: null,
      userId: null,
      userDetails: null,
      userWalletDetails: null,
      userTradingStats: null,
    };
  },
  methods: {
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/all_users" });
    },
    async fetchUserDetails() {
      try {
        const response = await UserService.getSingleUserDetails({
          userId: this.userId,
        });
        this.userDetails = response.data.user;
        this.userWalletDetails = response.data.userWallet;
        this.userTradingStats = response.data.userTradingStats;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    this.userId = this.$route.params.id;
    this.fetchUserDetails();
  },
};
</script>
