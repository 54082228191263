<template>
  <div class="card-body px-0">
    <p class="h3 text-center">
      Wallet
    </p>
    <p class="h5 mb-1">
      Wallet Balance:
      <span class="font-weight-normal">
        {{ userWalletDetails.currency }}
        {{ userWalletDetails.balance | formartNumber }}
      </span>
    </p>
    <p class="h5 mb-1">
      Giftcard Balance:
      <span class="font-weight-normal">
        {{ userWalletDetails.giftcardBalance | formartNumber }}
      </span>
    </p>
    <p class="h5 mb-1">
      Giftcard Traded:
      <span class="font-weight-normal">
        {{ userWalletDetails.giftcardTraded }}
      </span>
    </p>
    <div class="d-flex justify-content-start">
      <button
        class="mt-4 btnd btn-success"
        data-toggle="modal"
        data-target="#ResetConfirmationModal"
        type="button"
      >
        Reset User
      </button>
    </div>
    <ResetConfirmationModal :userDetails="userDetails" />
  </div>
</template>

<script>
import ResetConfirmationModal from "./ResetConfirmationModal.vue";

import Calls from "@/Calls";
export default {
  components: { ResetConfirmationModal },
  name: "UserWalletInfo",
  props: ["userWalletDetails", "userDetails"],

  filters: {
    formartNumber(val) {
      return Calls.formartNumber(val);
    },
    formartDate(val) {
      return Calls.formartDate(val);
    },
  },
};
</script>
