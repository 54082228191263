<template>
  <div>
    <div
      class="modal fade lg"
      id="txnModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              GiftCard Details
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card shadow">
              <div class="card-body">
                <TextTitleDisplay title="TxRef" :content="currentItem.txRef" />
                <TextTitleDisplay
                  title="TxFrom"
                  :content="currentItem.txFrom"
                />
                <TextTitleDisplay title="TxTo" :content="currentItem.txTo" />
                <TextTitleDisplay
                  title="TxService"
                  :content="currentItem.txService"
                />
                <TextTitleDisplay
                  title="Status"
                  :content="currentItem.status"
                />
                <TextTitleDisplay
                  v-if="currentItem.amount"
                  title="Amount"
                  :content="'&#8358;' + (currentItem.amount | formartNumber)"
                />
                <TextTitleDisplay
                  v-if="currentItem.amount"
                  title="Details"
                  :content="currentItem.details"
                />
                <TextTitleDisplay
                  v-if="currentItem.amount"
                  title="Date"
                  :content="currentItem.createdAt | formartDate"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextTitleDisplay from "../texts/TextTitleDisplay.vue";
import Calls from "@/Calls";
export default {
  name: "Modal",
  components: { TextTitleDisplay },
  filters: {
    formartNumber(val) {
      return Calls.formartNumber(val);
    },
    formartDate(val) {
      return Calls.formartDate(val);
    },
  },
  data() {
    return {
      loading: false,
    };
  },

  props: ["currentItem"],
};
</script>
