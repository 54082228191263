<template>
  <div>
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div>
          <p class="h5 mb-3">
            Transactions
          </p>
        </div>
        <div class="col-md-3">
          <div class="d-flex align-items-cener mb-3">
            <div class="input-group-append">
              <span class="bgBg py- px-3 border border-dark border-right-0">
                <i class="fa fa-search text-white lead pt-2"></i>
              </span>
            </div>
            <input
              class="border-left-0"
              placeholder="Search"
              v-model="search"
              type="search"
              prepend
            />
          </div>
        </div>
      </div>
    </div>
    <v-data-table
      :items-per-page="6"
      :headers="headers"
      :items="usersTxn"
      :search="search"
      item-key="name"
    >
      <template v-slot:body="{ items, headers }">
        <tbody name="list" is="transition-group" v-if="items.length > 0">
          <tr v-for="(item, index) in items" :key="index" class="item-row">
            <td>
              <span class="p d-block mb-0">
                {{ item.createdAt | formartDate }}</span
              >
            </td>
            <td>{{ item.txService }}</td>

            <td>
              {{ item.txFrom }}
            </td>
            <td>&#8358;{{ item.amount | formartNumber }}</td>
            <td class="font-weight-bold">
              <span class="text-success" v-if="item.status == 'COMPLETED'">
                {{ item.status }}
              </span>
              <span class="text-danger" v-if="item.status == 'CANCELLED'">
                {{ item.status }}
              </span>
              <span class="text-warning" v-if="item.status == 'PENDING'">
                {{ item.status }}
              </span>
            </td>
            <td>
              <div class="d-flex align-items-">
                <button
                  @click="viewItem(item)"
                  type="button"
                  data-toggle="modal"
                  data-target="#txnModalCenter"
                  class="btnd btn-success border-0 text-light p px-2"
                >
                  <span class="p">
                    Details
                  </span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              v-if="!txnLoading"
              :colspan="headers.length"
              style="text-align: center"
            >
              There's No Txn Yet!
            </td>
            <td v-else style="text-align: center">
              Loading...
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <TxnDetailsModal v-if="showModal" :currentItem="currentItem" />
  </div>
</template>

<script>
import UserService from "@/services/user.service.js";
import TxnDetailsModal from "../../../components/wallet/TxnDetailsModal.vue";
import Calls from "@/Calls";

export default {
  components: { TxnDetailsModal },
  data() {
    return {
      usersTxn: [],
      headers: [
        {
          text: "Date",
          align: "start",
          value: "name",
        },
        {
          text: "Service",
          value: "service",
        },
        {
          text: "txTo",
          value: "txTo",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "View",
        },
      ],
      txnLoading: true,
      search: "",
      currentItem: {},
      showModal: false,
    };
  },
  filters: {
    formartNumber(val) {
      return Calls.formartNumber(val);
    },
    formartDate(val) {
      return Calls.formartDate(val);
    },
  },
  methods: {
    async fetchUsers({ userId }) {
      try {
        const response = await UserService.getUserTxn({ userId });
        this.usersTxn = response.data.reverse();
        this.txnLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async viewItem(val) {
      this.currentItem = val;
      this.showModal = true;
    },
  },
  props: ["userId"],
  created() {
    this.fetchUsers({ userId: this.userId });
  },
};
</script>

<style scoped></style>
