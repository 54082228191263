<template>
  <div>
    <div class="card-body px-0">
      <div class="row no-gutters align-items-start">
        <div class="col-md-3">
          <img
            v-if="userDetails.profilePicture"
            :src="
              'http://uploads.wtnloiey.easyflip.ng/' +
                userDetails.profilePicture
            "
            class="rounded-circle d-block"
            height="100"
            width="100"
            alt=""
          />
          <img
            v-else
            src="https://artscimedia.case.edu/wp-content/uploads/sites/79/2016/12/14205134/no-user-image.gif"
            class="rounded-circle w-100 d-block"
            alt=""
          />
          <br />
        </div>
        <div class="col-md-9 ">
          <p class="h3 mb-2">
            {{ userDetails.firstName }} {{ userDetails.lastName }}
          </p>
          <div class="row no-gutters">
            <div class="col-md-5">
              <p class="h5 mb-2">Basic details</p>
              <p class="h6 mb-2 font-weight-normal">
                <span class="font-weight-bold">
                  User Id:
                </span>
                {{ userDetails.userId }}
              </p>

              <p class="h6 mb-2 font-weight-normal">
                <a :href="'mailto:' + userDetails.email">{{
                  userDetails.email
                }}</a>
              </p>
              <p class="h6 mb-1 font-weight-normal">
                <a :href="'tel:+234' + userDetails.phoneNumber">
                  {{ "+234" + userDetails.phoneNumber }}</a
                >
              </p>
              <p class="h6 mb-1 font-weight-normal">
                <span v-if="userDetails.gender">
                  {{ userDetails.gender }}
                </span>
                <span v-else>
                  Gender Not Set
                </span>
              </p>
              <p class="h6 mb-1 font-weight-normal">
                <span v-if="userDetails.address">
                  {{ userDetails.address }}
                </span>
                <span v-else>
                  Address Not Set
                </span>
              </p>
              <p class="h6 mb-1 font-weight-normal">
                <span v-if="userDetails.age">
                  {{ userDetails.age }}
                </span>
                <span v-else>
                  Age Not Set
                </span>
              </p>
            </div>
            <div class="col-md-7">
              <p class="h5 mb-2">Nok Details</p>
              <p class="h6 mb-1 font-weight-normal">
                <span class="font-weight-bold">
                  FirstName:
                </span>
                <span v-if="userDetails.nokFirstName">
                  {{ userDetails.nokFirstName }}
                </span>
                <span v-else>
                  Not Set
                </span>
              </p>
              <p class="h6 mb-1 font-weight-normal">
                <span class="font-weight-bold">
                  LastName:
                </span>
                <span v-if="userDetails.nokLastName">
                  {{ userDetails.nokLastName }}
                </span>
                <span v-else>
                  Not Set
                </span>
              </p>
              <p class="h6 mb-1 font-weight-normal">
                <span class="font-weight-bold">
                  Email:
                </span>
                <span v-if="userDetails.nokEmail">
                  {{ userDetails.nokEmail }}
                </span>
                <span v-else>
                  Not Set
                </span>
              </p>
              <p class="h6 mb-1 font-weight-normal">
                <span class="font-weight-bold">
                  PhoneNumber:
                </span>
                <span v-if="userDetails.nokPhoneNumber">
                  {{ userDetails.nokPhoneNumber }}
                </span>
                <span v-else>
                  Not Set
                </span>
              </p>
              <p class="h6 mb-1 font-weight-normal">
                <span class="font-weight-bold">
                  Country:
                </span>
                <span v-if="userDetails.nokPhoneNumber">
                  {{ userDetails.nokPhoneNumber }}
                </span>
                <span v-else>
                  Not Set
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calls from "@/Calls";
export default {
  components: {},
  name: "UserBasicInfo",
  props: ["userDetails"],
  filters: {
    formartNumber(val) {
      return Calls.formartNumber(val);
    },
    formartDate(val) {
      return Calls.formartDate(val);
    },
  },
};
</script>
