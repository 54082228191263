<template>
  <div>
    <div
      class="modal fade"
      id="ResetConfirmationModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Reset User Account
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card shadow">
              <div v-if="!resetData" class="card-body">
                <p class="mb-1 h6">
                  User Id:
                  <span class=" font-weight-normal">
                    {{ userDetails.userId }}
                  </span>
                </p>

                <p class="mb-1 h6">
                  FullName:
                  <span class="font-weight-normal">
                    {{ userDetails.firstName }} {{ userDetails.lastName }}</span
                  >
                </p>
                <p class="mb-1 h6">
                  Email:
                  <span class="font-weight-normal">
                    {{ userDetails.email }}
                  </span>
                </p>

                <p class="m-0 p-0 text-danger text-center mt-2 mb-4">
                  ARE YOU SURE ABOUT THIS?
                </p>
                <div class="row justify-content-around">
                  <div>
                    <button
                      :disabled="loading"
                      v-if="!resetLoading"
                      @click="Reset"
                      class="btn btn-danger"
                    >
                      RESET
                    </button>
                    <button
                      v-else
                      class="btn btn-danger"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="card-body">
                <p class="text-center">Reset Password is: {{ resetData }}</p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service";

export default {
  name: "ResetConfirmationModal",

  data() {
    return {
      loading: false,
      resetLoading: false,
      resetData: null,
    };
  },
  methods: {
    async Reset() {
      this.resetLoading = true;
      this.loading = true;
      try {
        const response = await UserService.reset(this.userDetails.userId);
        this.resetData = response.data;
        this.$toast.open({
          message: response.msg,
          position: "top-right",
          type: "success",
          duration: 2500,
          pauseOnHover: true,
        });
      } catch (e) {
        this.$toast.open({
          message: e.msg,
          position: "top-right",
          type: "error",
          duration: 2500,
          pauseOnHover: true,
        });
      }
      this.resetLoading = false;
      this.loading = false;
    },
  },
  props: ["userDetails"],
};
</script>
