<template>
  <div class="card-deck my-5">
    <div class="card col-md-6">
      <div class="card-body">
        <p class="h4">Bank Details</p>
        <div class="card  border-0 mb-5">
          <div class="card-body bg-light">
            <p class="h6 mb-1 font-weight-normal">
              <span class="font-weight-bold">
                Bank Name:
              </span>
              <span v-if="userDetails.bankName">
                {{ userDetails.bankName }}
              </span>
              <span v-else>
                Not Set
              </span>
            </p>
            <p class="h6 mb-1 font-weight-normal">
              <span class="font-weight-bold">
                Account Number:
              </span>
              <span v-if="userDetails.accountNumber">
                {{ userDetails.accountNumber }}
              </span>
              <span v-else>
                Not Set
              </span>
            </p>
            <p class="h6 mb-1 font-weight-normal">
              <span class="font-weight-bold">
                Account Name:
              </span>
              <span v-if="userDetails.accountName">
                {{ userDetails.accountName }}
              </span>
              <span v-else>
                Not Set
              </span>
            </p>
            <p class="h6 mb-1 font-weight-normal">
              <span class="font-weight-bold">
                Bvn:
              </span>
              <span v-if="userDetails.bvn">
                {{ userDetails.bvn }}
              </span>
              <span v-else>
                Not Set
              </span>
            </p>
          </div>
          <div class="card-body bg-light mt-3">
            <p class="h6 mb-1 font-weight-normal">
              <span class="font-weight-bold">
                NIN:
              </span>
              <span v-if="userDetails.nin">
                {{ userDetails.nin }}
              </span>
              <span v-else>
                Not Set
              </span>
            </p>
            <p class="h6 mb-1 font-weight-normal">
              <span class="font-weight-bold">
                Date Of Birth:
              </span>
              <span v-if="userDetails.dob">
                {{ userDetails.dob }}
              </span>
              <span v-else>
                Not Set
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card col-md-6">
      <div class="card-body px-0">
        <p class="h4">Valid ID</p>
        <div class="card  border-0 mb-3">
          <div class="card-body bg-light">
            <img
              v-if="userDetails.validID"
              :src="
                'http://uploads.wtnloiey.easyflip.ng/' + userDetails.validID
              "
              class="w-100 h-100"
              alt=""
            />
            <p v-else class="text-center text-danger">Not Uploaded</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserKycInfo",
  props: ["userDetails"],
};
</script>
